import React, { useState,useEffect, useCallback } from 'react'
import './login.css'
import i18next from "i18next";
import { getToken, loginWithToken } from '../api/loginChat'
import { createHashHistory } from 'history';
// import { useHistory } from 'react-router-dom'


import store from '../redux/store'
import { setMyUserInfo } from '../redux/actions'
import loading from '../assets/loading.png'
import closeIcon from '../assets/Xmark@2x.png'
import eyeOpen from '../assets/eye@2x.png'
import eyeClose from '../assets/eye_slash@2x.png'

export default function Login() {
    const history = createHashHistory()
    const [notice, setNotice] = useState({
        show: false,
        text: ''
    })

    const [activeType, setActiveType] = useState('password')
    const [disabled, setdisabled] = useState(true)
    const [loginBtn, setLoginBtn] = useState(i18next.t('login-Login'))

    const queryParameters = new URLSearchParams(window.location.search)
    const userM = queryParameters.get("user");
    const timeM = queryParameters.get("t");
    const webim_auth = JSON.parse(sessionStorage.getItem('webim_auth'))
	const isLoadingM = sessionStorage.getItem('isLoadingM');
    console.log(webim_auth);
	const isLoadedM = webim_auth && webim_auth.password &&webim_auth.agoraId==userM&&timeM==webim_auth.timeM;
	
    const [values, setValues] = useState({
        agoraId: userM,
        nickName: '',
        password: userM,
        showPassword: false,
        timeM:timeM
    });
    console.log(values);
    console.log(isLoadingM);

    if (isLoadedM) {
		console.log("로그인 되어있음");
		window.location.href = "/#/main";
		return null;
    } else if(isLoadingM!="true"){
		console.log("로그인 시도");
		sessionStorage.removeItem('webim_auth')
		sessionStorage.setItem('isLoadingM', "true");
	
        getToken(userM, userM).then((res) => {
            const { accessToken, agoraUid } = res
            loginWithToken(userM.toLowerCase(), accessToken).then(value => {
			console.log("로그인 완료");
            }).catch(err => {
                console.log(err)
                setNotice({ show: true, text: 'Wrong Username or Password' })
            })
            store.dispatch(setMyUserInfo({ agoraId: userM, password: userM }))
			sessionStorage.removeItem('isLoadingM')
            sessionStorage.setItem('webim_auth', JSON.stringify({ ...values, accessToken, agoraUid }))
        }).catch(() => {
            setNotice({ show: true, text: 'login fail' })
            setdisabled(true)
        })
    }
    return (
        <div className='login-container'>
        </div>
    )
}
